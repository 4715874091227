import { Controller } from "stimulus";

// IN USE FOR src/caas/templates/caas_report/customer/selection.pug CUSTOMER SELECTION FILTERING.
export default class extends Controller {

	static targets = ["listItem", "input"];

	connect() {
		this.inputTarget.focus();
		this.inputTarget.select();
	}

	disconnect() { }

	filterList(event) {
		const search_value = event.target.value.toLowerCase().trim();
		this.listItemTargets.forEach(elem => {
			const item_text = elem.textContent.toLowerCase();
			const parent = elem.parentElement;
			const grandparent = parent.parentElement;
			if (item_text.indexOf(search_value) != -1) {
				parent.classList.remove('hidden');
				grandparent.classList.remove('hidden');
			}
			else {
				parent.classList.add('hidden');
				grandparent.classList.add('hidden');
			}
		});
	}
}
