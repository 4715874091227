import { Controller } from "stimulus";

// IN USE FOR CAAS FILTERS FORMS
// ENABLES AND DISABLES THE PASS/FAIL/NO-ACCESS & CIS-1/CIS-2/NTT & RISK LEVELS (LOW, MEDIUM, HIGH) CHECKOX FILTERS
export default class extends Controller {

  static targets = ["hiddenCtrl", "visibleCtrl"];

  connect() { }

  disconnect() { }

  change(event) {
    event.target.classList.toggle("disabled");
    this.hiddenCtrlTarget.checked = !event.target.classList.contains("disabled");

    const hidden_change_event = new Event('change');
    this.hiddenCtrlTarget.dispatchEvent(hidden_change_event);
  }

}
